<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      {{ $t('Role List') }}
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-users-role-create' }"
      back-to-list-path="settings-users-role-list"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/trashList`,
      }"
    >
      <template #cell(is_active)="{ data }">
        {{ $t(data.value ? 'Active': 'Inactive') }}
      </template>
      <template #cell(actions)="{ data }">
        <div
          class="text-nowrap d-flex"
          style="gap: 8px"
        >
<!--          <feather-icon-->
<!--              v-b-tooltip.noninteractive.hover.bottom="$t('Update')"-->
<!--              icon="LEyeIcon"-->
<!--              class="featherIcon cursor-pointer border-dotted"-->
<!--              size="16"-->
<!--              @click="gotoNewPage({ name: 'settings-users-role-update', params: { id: data.item.id } }, $event)"-->
<!--          />-->
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom="$t('Delete')"
            icon="LTrashIcon"
            size="16"
            class="featherIcon cursor-pointer border-dotted"
            @click="remove(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreContact(data.item.id)"
          />
        </div>
      </template>
      <template #filter>
        {{ '' }}
      </template>
    </l-table>
  </div>
</template>

<script>

import { VBTooltip } from 'bootstrap-vue'

import LTable from '@/views/components/LTable/LTable.vue'
import tableConfig from '../roleConfig'

export default {
  name: 'RoleList',
  components: {
    LTable,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`).then(() => {
        this.refetchData()
      })
    },
    update(data) {
      this.$router.push({ name: 'settings-users-role-update', params: { id: data.id } })
    },
    restoreContact(data) {
      this.confirmNotification(this, [data], `${this.MODULE_NAME}/restoreFromTrashList`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const MODULE_NAME = 'roles'
    const { tableColumns } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
    }
  },
}
</script>

<style scoped>

</style>
