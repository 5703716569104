import { render, staticRenderFns } from "./RoleListTashList.vue?vue&type=template&id=f2c1b95c&scoped=true"
import script from "./RoleListTashList.vue?vue&type=script&lang=js"
export * from "./RoleListTashList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2c1b95c",
  null
  
)

export default component.exports